<template>
    <div v-if="!$route.path.includes('sessions') && !$route.path.includes('set-sec-password') && !$route.path.includes('register') && !$route.path.includes('rank-details')  && !$route.path.includes('withdraw') && !$route.path.includes('sellBoost') && !$route.path.includes('redeemBoost') && !$route.path.includes('vapi')" class="footer_wrap mt-auto" ref="footer">
        <!-- Footer Start -->
        <div class="app-footer">
            <b-row align-h="between" class="">
                <b-col cols="3" class="position-relative col-auto">
                    <router-link tag="a" to="/web/homepage" class="nav-item-hold">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'homepage', 'inactive': $parent.path != 'homepage' }">
                            <img :src="$parent.path == 'homepage' ? require('./assets/images/footer/home-active.png') : require('./assets/images/footer/home.png')"
                                class="nav-icon" />
                        </div>
                        <div :class="{ 'nav-text': true, 'active': $parent.path == 'homepage', 'inactive': $parent.path != 'homepage' }">
                            {{ $t('home') }}
                        </div>
                    </router-link>
                </b-col>
                <!-- <b-col cols="" class="col-auto">
                    <router-link tag="a" to="/web/wallet" class="nav-item-hold ">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'wallet', 'inactive': $parent.path != 'wallet' }">
                            <img :src="$parent.path == 'wallet' ? require('./assets/images/footer/wallet-active.png') : require('./assets/images/footer/wallet-icon.png')"
                                class="nav-icon" />
                        </div>
                    </router-link>
                </b-col> -->

                <b-col cols="3" class="col-auto">
                    <router-link tag="a" to="/web/package" class="nav-item-hold">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'package', 'inactive': $parent.path != 'package' }">
                            <img :src="$parent.path == 'package' ? require('./assets/images/footer/package-active.png') : require('./assets/images/footer/package.png')"
                                class="nav-icon" />
                        </div>
                        <div :class="{ 'nav-text': true, 'active': $parent.path == 'package', 'inactive': $parent.path != 'package' }">
                            {{ $t('package') }}
                        </div>
                    </router-link>
                </b-col>

                <b-col cols="3" class="col-auto">
                    <router-link tag="a" to="/web/team" class="nav-item-hold">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'team', 'inactive': $parent.path != 'team' }">
                            <img :src="$parent.path == 'team' ? require('./assets/images/footer/team-active.png') : require('./assets/images/footer/team.png')"
                                class="nav-icon" />
                        </div>
                        <div :class="{ 'nav-text': true, 'active': $parent.path == 'team', 'inactive': $parent.path != 'team' }">
                            {{ $t('team') }}
                        </div>
                    </router-link>
                </b-col>

                <b-col cols="3" class="col-auto">
                    <router-link tag="a" to="/web/settings" class="nav-item-hold">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'settings', 'inactive': $parent.path != 'settings' }">
                            <img :src="$parent.path == 'settings' ? require('./assets/images/footer/settings-active.png') : require('./assets/images/footer/settings.png')"
                                class="nav-icon" />
                        </div>
                        <div :class="{ 'nav-text' : true, 'active': $parent.path == 'settings', 'inactive': $parent.path != 'settings' }">
                            {{ $t('settings') }}
                        </div>
                    </router-link>
                </b-col>
            </b-row>
            <!-- fotter end -->
        </div>
    </div>

</template>
<script>
export default{

}
</script>