import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

var DEFAULT_LANG = "en";

var savedLan = localStorage.getItem('lan');

if (savedLan) {
  DEFAULT_LANG = savedLan;

}

const locales = {
  en: require("./i18n/en.json"),
  zh: require("./i18n/zh.json"),
  in: require("./i18n/in.json"),
  zhHant: require("./i18n/zhHant.json"),
  ja: require("./i18n/ja.json"),
  ko: require("./i18n/ko.json"),
  vn: require("./i18n/vn.json"),
};
const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales
});

export default i18n;
