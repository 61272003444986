

export default async (to, from, next) => {
  var token = localStorage.getItem("currencyToken") || '';

  console.log(to);

    if (token.length > 0) {
      next();
    } else {
      localStorage.removeItem("currencyToken");
      next('/web/sessions/login');
    }

};
