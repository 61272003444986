import axios from 'axios';

const service = axios.create({
	baseURL: process.env.BASE_API,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
		'Content-Type': 'application/json',
	},
	timeout: 90000
});

service.interceptors.request.use(function (config) {
	// Do something before request is sent
	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});

service.interceptors.response.use(function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
}, function (error) {
	if (error.response.status == 401) {
        localStorage.removeItem('address');
        localStorage.removeItem('currencyToken');
		var current = location.origin + '/web';
		window.location.href = current;
	}
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	return Promise.reject(error);
});


export default service;