<template>
  <b-modal id="forget_secpassword" centered :title="$t('forget_secpassword')" hide-footer>
    <div class="text-left">
      <b-form @submit.prevent="sendOTP">
        <b-form-group class="">
          <h4 class="font-weight-semibold text-14">{{ $t('email') }}</h4>
          <b-input-group>
            <b-form-input class="form-control form-custom" v-model="email" type="text"
              :placeholder="$t('email')" readonly></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group class="">
          <h4 class="font-weight-semibold text-14">{{ $t('vcode') }}</h4>
          <b-input-group>
            <b-form-input class="no-border" label="text" type="number" v-model="otp" :placeholder="$t('vcode')" required>
            </b-form-input>
            <template #append>
              <b-button variant="primary" @click="getOTP" :disabled="startCount || sending">
                {{ startCount ? timecount + 's' : !sending ? $t('getCode') : $t("loading...") }}
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>
        <b-row align-h="center">
          <b-col cols="12">
            <div class="form-group row">
              <div class="col-12">
                  <h5 class="label-form font-weight-bold mb-2 text-16">{{ $t('digits_pin') }}</h5>
                  <div class="input-group digits-box">
                      <input v-for="(digit, index) in digits" :key="index" type="password"
                          class="form-control" v-model="digits[index]"
                          @input="updateDigit(index, $event.target.value)" @keydown="handleKeydown(index, $event)" pattern="[0-9]"
                          required maxlength="1" ref="inputFields" inputmode="numeric"/>
                  </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                  <h5 class="label-form font-weight-bold mb-2 text-16">{{ $t('confirm_digits_pin') }}</h5>
                  <div class="input-group digits-box">
                      <input v-for="(digit2, index2) in digits2" :key="index2" type="password"
                          class="form-control" v-model="digits2[index2]"
                          @input="updateDigit2(index2, $event.target.value)" @keydown="handleKeydown2(index2, $event)" pattern="[0-9]"
                          required maxlength="1" ref="inputFields2" inputmode="numeric"/>
                  </div>
              </div>
            </div>
          </b-col>
        </b-row>  
        <!-- <b-form-group class="mt-4">
          <h4 class="font-weight-semibold text-14">{{ $t('new_password') }}</h4>
          <b-input-group>
            <b-form-input class="form-control form-custom" v-model="password" type="password"
              :placeholder="$t('new_password')" required></b-form-input>
          </b-input-group>
        </b-form-group>


        <b-form-group class="mt-4">
          <h4 class="font-weight-semibold text-14">{{ $t('confirm_new_password') }}</h4>
          <b-input-group>
            <b-form-input class="form-control form-custom" v-model="password_confirmation" type="password"
              :placeholder="$t('confirm_new_password')" required></b-form-input>
          </b-input-group>
        </b-form-group> -->
        <b-button class="d-block mt-4 ml-auto px-4" variant="primary" type="submit" :disabled="$root.isLoading">{{
          isLoading ? $t("loading...") : $t("submit") }}</b-button>


      </b-form>

    </div>
  </b-modal>
</template>

<script>
import {
  requestOTP,
  checkOTP,
  forgetSecPassword,
} from "../../../system/request/api/api";
import { handleError } from "../../../system/request/handleRes";
export default {
  computed: {
    fullPIN() {
      return this.digits.join('');
    },
    fullPIN2() {
      return this.digits2.join('');
    },
  },
  components: {
  },
  data() {
    return {
      email: "",
      country: "",
      otp: "",
      password: "",
      password_confirmation: "",
      timecount: 60,
      startCount: false,
      myVar: null,
      sending: false,
      isLoading: false,
      digits: Array(6).fill(''),
      digits2: Array(6).fill(''), 
    };
  },
  props: ["success"],
  methods: {
    updateDigit(index, value) {
        this.digits[index] = value;

        if (index < this.digits.length - 1) {
            this.$refs.inputFields[index + 1].focus();
        }
    },
    updateDigit2(index2, value) {
        this.digits2[index2] = value;

        if (index2 < this.digits2.length - 1) {
            this.$refs.inputFields2[index2 + 1].focus();
        }
    },
    handleKeydown(index, event) {
      if (event.key === 'Backspace') {
        if (!this.digits[index] && index > 0) {
          this.digits[index - 1] = '';
          this.$refs.inputFields[index - 1].focus();
        } else {
          this.digits[index] = '';
        }
      }
    },
    handleKeydown2(index2, event) {
      if (event.key === 'Backspace') {
        if (!this.digits2[index2] && index2 > 0) {
          this.digits2[index2 - 1] = '';
          this.$refs.inputFields2[index2 - 1].focus();
        } else {
          this.digits2[index2] = '';
        }
      }
    },
    getInfo() {
      var info = this.$root.userInfo;

      this.email = info.email;
    },
    sendOTP() {
      let formData = new FormData();
      var self = this;
      formData.append("otp", this.otp);
      var result = checkOTP(formData);
      self.isLoading = true;

      result
        .then(function (value) {
          console.log(value.data);
          if (value.data.code.toString() == '0') {
            self.$root.makeToast("success", self.$t(value.data.message));
            self.submitForm();
          } else {
            self.$root.makeToast("danger", self.$t(value.data.message));
          }
          self.sending = false;
          self.isLoading = false;
        })
        .catch(function (error) {
          self.$root.makeToast("warning", self.$t(handleError(error)));
          self.sending = false;
          self.isLoading = false;
        });
    },
    submitForm() {
      let formData = new FormData();
      var self = this;
      formData.append("otp", this.otp);
      formData.append("password", this.fullPIN);
      formData.append("password_confirmation", this.fullPIN2);
      var result = forgetSecPassword(formData);
      self.isLoading = true;

      result
        .then(function (value) {
          console.log(value.data);
          if (value.data.error_code == 0) {
            self.$root.makeToast("success", self.$t(value.data.message));
            self.otp = '';
            self.fullPIN = '';
            self.fullPIN2 = '';
            self.$bvModal.hide('forget_secpassword');
          } else {
            self.$root.makeToast("danger", self.$t(value.data.message));
          }
          self.sending = false;
          self.isLoading = false;
        })
        .catch(function (error) {
          self.$root.makeToast("warning", self.$t(handleError(error)));
          self.sending = false;
          self.isLoading = false;
        });
    },
    getOTP() {
      if (this.username == "") {
        this.$root.makeToast("danger", this.$t("emailEmpty"));
      } else {
        this.sending = true;
        this.isLoading = true;
        let formData = new FormData();
        var self = this;
        formData.append("otp_type", "email");
        var result = requestOTP(formData);

        result
          .then(function (value) {
            console.log(value.data);
            if (value.data.code.toString() == '0') {
              self.$root.makeToast("success", self.$t("otp_sent"));
              self.startCount = true;
              self.myVar = setInterval(() => {
                self.timecount -= 1;
                if (self.timecount == 0) {
                  self.timecount = 60;
                  clearInterval(self.myVar);
                  self.startCount = false;
                }
              }, 1000);
            } else {
              self.$root.makeToast("danger", self.$t(value.data.message));
            }
            self.sending = false;
            self.isLoading = false;
          })
          .catch(function (error) {
            self.$root.makeToast("warning", self.$t(handleError(error)));
            self.sending = false;
            self.isLoading = false;
          });
      }
    },
  },
  created() {
    this.getInfo();
    this.username = localStorage.getItem("username");
  },
};
</script>


